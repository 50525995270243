import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { RasaContext } from 'context'
import { HeaderComponent } from 'components/header/component'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Button, Modal, ModalBody, ModalFooter, Row, Input } from 'reactstrap'
import { DropdownComponent, MultiSelectDropdown, OnChangeEvent } from 'components/dropdown/component'

import './_styles.scss'

interface PromptTemplate {
  id: number,
  sequence: number,
  prompt: string,
  options: string,
}

interface EditablePromptTemplate extends PromptTemplate {
  changedFields: string[],
  invalidFields: string[],
}

interface AIEngine {
  id: number,
  is_default?: boolean,
  engine_name: string,
  engine_version: string,
  templates: PromptTemplate[],
}

interface PromptType {
  engines: AIEngine[],
  id: number,
  name: string,
  description: string,
  defaultEngine: AIEngine,
}

const NO_ENGINE: AIEngine = {
  id: -1,
  engine_name: '',
  engine_version: '',
  is_default: false,
  templates: [],
}

interface EditablePromptType extends PromptType {
  isDirty?: boolean,
}

const NO_PROMPT_TYPE: PromptType = {
  engines: [ NO_ENGINE ],
  id: -1,
  name: '',
  description: '',
  defaultEngine: NO_ENGINE
}

interface State {
  identifier: string,
  editPrompt: EditablePromptType,
  engines: AIEngine[],
  promptTypes: PromptType[]
  selectedPrompt: number,
  selectedEngine: number,
  editableTemplates: EditablePromptTemplate[],
  editingTemplates: boolean,
}

const emptyState: State = {
  identifier: '',
  editPrompt: NO_PROMPT_TYPE,
  engines: [],
  promptTypes: [],
  selectedPrompt: -1,
  selectedEngine: -1,
  editableTemplates: [],
  editingTemplates: false,
}

class RexPromptsComponent extends RasaReactComponent<any, State>{
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props, "rexPrompts", emptyState)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        identifier: activeCommunity.identifier,
      }, () => {
        return new Dataset().loadCommunityDataset(
          'aiPromptTypes',
          activeCommunity.communityId,
          []
        ).then((results) => {
          this.setState({
            engines: results.engines.map((e) => ({
              ...e,
              engine_name: e.name,
              engine_version: e.version,
            })),
            promptTypes: results.prompts.map((prompt) => ({
              ...prompt,
              defaultEngine: prompt.engines.find((e) => e.is_default) || {}
            })),
          })
        })
      })
    })
  }

  public render = () => {
    return <div className="rex-ai-prompts">
      <HeaderComponent
        icon="t-rex"
        title={'AI Prompts'}
        subTitle='Manage the AI Prompts'
        description={[
          'View and edit AI prompts used to guide t-rex.',
        ]}
      />
      <Row>
        { this.promptTypesTable()}
      </Row>
      {this.promptsModal()}
      {this.enginesModal()}
    </div>
  }

  private promptTypesTable = () => {
    return <Grid data={this.state.promptTypes}>
      <GridColumn field="name" title="Prompt Type" />
      <GridColumn field="description" title="Description" />
      <GridColumn title="Primary" cell={this.primaryEngineCell}/>
      <GridColumn title="Alternates" cell={this.alternateEnginesCell}/>
      <GridColumn title="Edit" cell={this.editPromptCell}/>
      <GridColumn title="Prompts" cell={this.viewPromptsCell}/>
    </Grid>
  }

  private engineLabel = (
    engine: AIEngine, showDefault: boolean
  ): string  => {
    const labelParts = []
    if (showDefault && engine.is_default) {
      labelParts.push('*')
    }

    labelParts.push(engine.engine_name)
    labelParts.push(engine.engine_version)
    return labelParts.join(' ')
  }

  private primaryEngineCell = (props: any) => {
    return <td>
      <span>{this.engineLabel(props.dataItem, false)}</span>
    </td>
  }

  private clonePrompt = (prompt: PromptType) => {
    return {
      ...prompt,
      engines: prompt.engines.map((engine) => {
        return {
          ...engine
        }
      })
    }
  }

  private alternateEnginesCell = (props: any) => {
    const secondaryEngines = props.dataItem.engines.filter((engine: AIEngine) => {
      return engine.engine_name !== props.dataItem.engine_name ||
             engine.engine_version !== props.dataItem.engine_version
    })
    return <td>
        <span>{secondaryEngines.map((e) => this.engineLabel(e, false)).join(',')}</span>
      </td>
  }

  private viewPromptsCell = (props: any) => {
    return <td>
      <Button onClick={() => this.setState({
        selectedPrompt: props.dataItem.id,
        selectedEngine: props.dataItem.engines[0].id,
        editableTemplates: this.toEditableTemplates(props.dataItem.engines[0].templates)
      })}>
        View 
      </Button>
    </td>
  }

  private editPromptCell = (props: any) => {
    return <td>
      <Button onClick={() => this.setState({
        editPrompt: this.clonePrompt(props.dataItem),
      })}>
        Edit 
      </Button>
    </td>
  }

  protected toEditableTemplates = (templates: PromptTemplate[]): EditablePromptTemplate[] => {
    return templates.map((t) => {
      return {
        ...t,
        isValid: true,
        invalidFields: [],
        changedFields: []
      }
    })
  }

  private engineItem = (engine: AIEngine) => ({
    value: engine.id,
    label: this.engineLabel(engine, false)
  })

  protected enginesModal = () => {
    return <div className="ai-prompt-modal">
        <Modal isOpen={this.state.editPrompt.id >= 0} className="ai-prompt-modal">
          <ModalBody>
            <Row>
              <h3>{this.state.editPrompt.name}</h3>
            </Row>
            <Row className="engines">
              Available:
              <MultiSelectDropdown
                   className="engines"
                   placeholder="Engines"
                   selected={this.state.editPrompt.engines.map((e) => this.engineItem(e))}
                   options={this.state.engines.map((engine: AIEngine) => this.engineItem(engine))}
                   onSelect={(selectedItems: any[]) => {
                    this.setState({
                      editPrompt: {
                        ...this.state.editPrompt,
                       isDirty: true,
                        engines: selectedItems.map((i) => i.value).map((id: number) => this.state.engines.find((engine: AIEngine) => engine.id === id))
                      }
                    })
                   }}
                 />
            </Row>
            <Row>
              Default:
              <DropdownComponent className="engines"
                data={this.state.editPrompt.engines.map((e: AIEngine) => {
                  return {
                    description: this.engineLabel(e, true),
                    key: e.id 
                  }
                })}
                selected={this.state.editPrompt.defaultEngine.id}
                onChange={(event: OnChangeEvent) => {
                  this.setState({
                    editPrompt: {
                      ...this.state.editPrompt,
                      isDirty: true,
                      defaultEngine: this.state.editPrompt.engines.find((engine) => engine.id === event.selected.key)
                    }
                  })
                }} />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.savePrompt} disabled={!this.state.editPrompt.isDirty}>
              Save
            </Button>
            <Button onClick={() => this.setState({ 
              editPrompt: NO_PROMPT_TYPE
            })}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
    </div>
  }

  protected promptsModal = () => {

    const selectedPrompt = this.state.promptTypes.filter((p: PromptType) => p.id === this.state.selectedPrompt)[0] || NO_PROMPT_TYPE
    const selectedEngine = selectedPrompt.engines.filter((e: AIEngine) => e.id === this.state.selectedEngine)[0] || selectedPrompt.engines[0]

    return <div className="ai-prompt-modal">
        <Modal isOpen={this.state.selectedPrompt >= 0} className="ai-prompt-modal">
          <ModalBody>
            <Row>
              <h3>{selectedPrompt.name}</h3>
            </Row>
            <Row>
              {selectedPrompt.description}
            </Row>
          { selectedPrompt.engines.length > 1 && !this.state.editingTemplates
          ? 
            <Row>
              <DropdownComponent className="engines"
                data={selectedPrompt.engines.map((e: AIEngine) => {
                  return {
                    description: this.engineLabel(e, true),
                    key: e.id
                  }
                  })
                }
                selected={selectedEngine.id}
                onChange={(e: OnChangeEvent) => this.setState({ 
                  selectedEngine: e.selected.key as number,
                })}
              />
            </Row>
          :
            <Row className="engine-label">
              <span>Engine: {this.engineLabel(selectedEngine, false)}</span>
            </Row>
          }
            <Row>
              <Grid data={this.state.editableTemplates}>
                <GridColumn field="id" title="ID"/>
                <GridColumn field="sequence" title="Sequence" cell={this.editTemplateCell}/>
                <GridColumn field="prompt" title="Prompt" cell={this.editTemplateCell}/>
                <GridColumn field="options" title="Options" cell={this.editTemplateCell}/>
              </Grid>
            </Row>
          { this.state.editingTemplates ?
            <Row>
              <Button onClick={this.addTemplate}>Add Template</Button>
            </Row>
          : null }
          </ModalBody>
          <ModalFooter>
            <Button disabled={this.state.editingTemplates} onClick={() => this.setState({
              editingTemplates: true
            })}>
              Edit
            </Button>
            <Button disabled={!this.validTemplates()} onClick={this.saveTemplates} >
              Save
            </Button>
            <Button onClick={() => this.setState({ 
              selectedPrompt: -1, 
              selectedEngine: -1, 
              editableTemplates: [],  
              editingTemplates: false
            })}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>

  }

  private addTemplate = () => {
    const minId = Math.min(...this.state.editableTemplates.map((t) => t.id), 0)
    const maxSequence = Math.max(...this.state.editableTemplates.map((t) => t.sequence), 0)
    this.setState({
      editableTemplates: this.state.editableTemplates.concat([{
        id: minId - 1,
        sequence: maxSequence + 1,
        prompt: '',
        options: '',
        changedFields: [],
        invalidFields: [],
      }])
    })
  }

  private savePrompt = () => {
    const activeEngines: any[] = this.state.editPrompt.engines.map((engine) => ({
      aiEngineId: engine.id,
      isDefault: engine.id === this.state.editPrompt.defaultEngine.id,
      isActive: true,
    }))
    const activeEngineIds = activeEngines.map((e) => e.aiEngineId)

    const inactiveEngines: any[] = this.state.promptTypes.find((p: PromptType) => p.id === this.state.editPrompt.id)
      .engines.filter((engine) => !activeEngineIds.includes(engine.id))
      .map((engine) => ({
      aiEngineId: engine.id,
      isActive: false,
    }))

    return this.context.entityMetadata
     .getEntityObject('aiPromptType')
     .then((entity) => {
       entity.setRecordId(this.state.communityId, this.state.editPrompt.id)
       entity.data.engines = activeEngines.concat(inactiveEngines)
       return entity.save().then(() => {
          this.setState({
            editPrompt: NO_PROMPT_TYPE
          })
       })
     })
  }

  private saveTemplates = () => {
    return this.context.entityMetadata
     .getEntityObject('aiPromptType')
     .then((entity) => {
       entity.setRecordId(this.state.communityId, this.state.selectedEngine)
       entity.data.aiEngineId = this.state.selectedEngine
       entity.data.templates = this.state.editableTemplates.filter((t: EditablePromptTemplate) => t.prompt.length > 0)
       return entity.save().then(() => {
          this.setState({
            editingTemplates: false,
            editableTemplates: [],
          })
       })
     })
  }

  private validTemplate = (template : EditablePromptTemplate): boolean => {
    return template.invalidFields.length === 0
  }

  private validTemplates = (): boolean => {
    return this.state.editableTemplates.every((t: EditablePromptTemplate) => this.validTemplate(t))
  }

  private editTemplateCell = (props: any) => {
    const className = (props.dataItem.invalidFields && props.dataItem.invalidFields.includes(props.field)) ? 'invalid' 
      : (props.dataItem.changedFields && props.dataItem.changedFields.includes(props.field)) ? 'changed' : '' 

    return <td className={className}>
      {this.state.editingTemplates
       ? <Input className={className}
                value={props.dataItem[props.field]}
                onChange={(e) => { this.editTemplateValue(props, e) }}/>
       : <span>{props.dataItem[props.field]}</span>
      }
    </td>
  }

  private editTemplateValue = (props: any, event: any) => {
    this.setState({
      editableTemplates: this.state.editableTemplates.map((et: EditablePromptTemplate) => {
        if (et.id === props.dataItem.id) {
          et[props.field] = event.target.value
          if (!et.changedFields) et.changedFields = []
          et.changedFields.push(props.field)
          if (!this.validateData(props.field, event.target.value)) {
            if (!et.invalidFields) et.invalidFields = []
            et.invalidFields.push(props.field)
          }
        }
        return et
      }),
    })
  }

  private validateData = (field: string, value: string): boolean => {
    if (field === 'sequence' ) {
      try {
        parseInt(value, 10)
      } catch (e) {
        return false
      }
    } 
    if (field === 'options') {
      try {
        JSON.parse(value)
      } catch (e) {
        return false
      }
    }
    return value.length > 0
  }
}

export const RexPrompts = GenericRedux.createConnect(
  RexPromptsComponent,
  "rex_prompts",
)
